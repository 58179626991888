import {
  GET_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  UPDATE_BULK_ORDER,
} from 'actions/types';

let cacheList = [];
const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Stores orders array details in redux state.
 * @param {[]} state
 * @param {{}} action
 * @returns {[]}
 */
function order(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${GET_ORDERS}_FAILURE`) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (type === `${GET_ORDERS}_SUCCESS`) {
    cacheList = payload;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${CREATE_ORDER}_SUCCESS`) {
    cacheList = [...cacheList, payload];
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (
    [`${UPDATE_ORDER}_SUCCESS`, `${UPDATE_BULK_ORDER}_SUCCESS`].includes(type)
  ) {
    const { id, updatedValues } = payload;
    const data = cacheList.map(function (order) {
      if (order.id === id) {
        return {
          ...order,
          ...updatedValues,
        };
      }
      return order;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${DELETE_ORDER}_SUCCESS`) {
    const { id } = payload;
    const data = cacheList.filter(function (order) {
      return order.id !== id;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  return state;
}

export default order;
