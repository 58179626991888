import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * If id is supplied, returns tag name matching the supplied id.
 * if name is supplied, returns tag id matching the supplied name.
 * If no match, returns the argument.
 * Id will take priority over name if both are supplied.
 * Returns tag id matching the supplied name or returns tag name if tag is not found.
 * @param {string} name
 * @returns {string}
 */
function useTagOption({ id, name }) {
  const value = id || name;
  const query = id ? 'id' : 'tagname';
  const result = id ? 'tagname' : 'id';
  const { data: tags, isLoading } = useSelector(({ tag }) => tag);
  const option = useMemo(() => {
    const tag = tags.find(function (tag) {
      return tag[query] === value;
    });
    if (tag) {
      return tag[result];
    }
    return value;
  }, [tags, isLoading]);
  return option;
}

export default useTagOption;
