import { useEffect } from 'react';
/**
 * @param {{
 * options : [{
 * value : string
 * label : string
 * }]
 * dependency : []
 * labels : [string]
 * preselect : boolean
 * value : string | object
 * onSelectChange : function
 * cleanupFunction : function
 * }} param
 */
function useSelectValue({
  value,
  labels,
  options = [],
  onSelectChange,
  dependency = [],
  cleanupFunction,
  preselect = false,
}) {
  useEffect(
    function () {
      if (preselect && options.length === 1) {
        onSelectChange(options[0]);
      } else if (labels) {
        const selected = options.filter(function (option) {
          return labels.includes(option.value);
        });
        if (selected && selected.length > 0) {
          onSelectChange(selected);
        }
      } else if (value) {
        if (typeof value === 'object') {
          const selected = options.find(function (option) {
            return value.id === option.value;
          });
          if (selected) {
            onSelectChange(selected);
          }
        } else {
          const selected = options.find(function (option) {
            return value === option.value;
          });

          if (selected) {
            onSelectChange(selected);
          }
        }
      }
      return function () {
        if (cleanupFunction) {
          cleanupFunction();
        }
      };
    },
    [...dependency],
  );
}

export default useSelectValue;
