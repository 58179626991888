import { endPoints } from 'api/';
import { DELETE_TAG, UPDATE_TAG } from 'actions/types';

/**
 * @description Updates tag object. This will also act as `delete` action by `disabling` status.
 * @param {string} tagId
 * @param {{}} payload
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns Tag object
 */
function update(tagId, payload, isDeleting = false) {
  const actionType = isDeleting ? DELETE_TAG : UPDATE_TAG;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const tag = await apiClient(endPoints.UPDATE_TAG, payload, tagId);
        return tag;
      },
    };
    dispatch(action);
  };
}

export default update;
