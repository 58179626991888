import { endPoints } from 'api/';
import { GET_TAGS } from 'actions/types';
import { active } from 'constants/status';

/**
 * @description Get a list of all tags.
 * @param {string} companyId Current user's company id.
 * @returns Array of tag objects
 */
function query(companyId) {
  const query = `?where={"company_id":"${companyId}","status":"${active}"}&include_default=true`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_TAGS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const tag = await apiClient(endPoints.GET_TAGS, {}, query);
        return tag;
      },
    };
    dispatch(action);
  };
}

export default query;
