import { endPoints } from 'api/';
import { DELETE_CONDITION, UPDATE_CONDITION } from 'actions/types';

/**
 * @description Updates condition object. This will also act as `delete` action by `disabling` condition.
 * @param {string} conditionId
 * @param {{}} payload
 * @param {boolean} isDeleting If set to true, it will still update the record but UI condition will be delete
 * @returns Condition object
 */
function update(conditionId, payload, isDeleting = false) {
  const actionType = isDeleting ? DELETE_CONDITION : UPDATE_CONDITION;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const condition = await apiClient(
          endPoints.UPDATE_CONDITION,
          payload,
          conditionId,
        );
        return condition;
      },
    };
    dispatch(action);
  };
}

export default update;
