import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signup } from 'actions/signup';
import { showNotify } from 'actions/notify/';
import { LOGIN_PATH } from 'constants/general';
import SignUpForm from 'components/SignUp/SignUp';

function SignUp() {
  const dispatch = useDispatch();
  const { message } = useSelector(({ notify }) => notify);

  /**
   * @param {FormData} user
   * @param {FormData} company
   */
  function onSubmit(company, user) {
    dispatch(signup(company, user));
  }

  function onBackClick() {
    window.location.assign(LOGIN_PATH);
  }

  useEffect(() => {
    // TODO : find an elegant way to achieve this ?
    const successful = 'Admin Successfully Created';
    if (message === successful) {
      onBackClick();
      dispatch(
        showNotify('Account successfully created, login with your details'),
      );
    }
  }, [message]);

  return <SignUpForm onSubmit={onSubmit} onBackClick={onBackClick} />;
}

export default SignUp;
