import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { updateItem } from 'actions/item/';
import { ItemSKU } from 'components/Order/';
import { clearModal } from 'actions/modals/';
import { GENERATE_SKU_MODAL } from 'constants/modal';

function ItemSKUModal() {
  const dispatch = useDispatch();
  const modal = useSelector(({ modal }) => modal);
  const isOpen = modal?.type === GENERATE_SKU_MODAL;
  const { id, name } = isOpen && modal?.initialValue;
  const modalCaption = `Generate SKU for item ${name} ?`;
  const { data: items } = useSelector(({ item }) => item);

  function onModalClose() {
    dispatch(clearModal(GENERATE_SKU_MODAL));
  }

  /**
   * @param {string} sku
   */
  function onSubmit(sku) {
    const item = items.find(function (item) {
      return item.id === id;
    });
    const { warehouse_id: warehouse } = item;
    const payload = new FormData();
    payload.append('id', id);
    payload.append('sku', sku);
    payload.append('warehouse_id', warehouse);
    dispatch(updateItem({ id, payload, source: GENERATE_SKU_MODAL }));
  }

  return (
    <Modal
      modalTop={25}
      modalLeft={30}
      isOpen={isOpen}
      modalWidth={40}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <ItemSKU onSubmit={onSubmit} />
    </Modal>
  );
}

export default ItemSKUModal;
