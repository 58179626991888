import { useMemo } from 'react';
import { capitalizeFirstLetter } from 'utils/';

/**
 * Receives array of object and transform it to array of select options,
 * value will always be id of object and label will be user defined.
 * @param {{
 *   label : string
 *   exclude : string[]
 *   capitalize : boolean
 *   items : [{
 *      id : string
 *      [key : string] : string
 *  }]
 *}} param
 */
function useSelectOption({
  items,
  exclude = [],
  label = 'name',
  capitalize = false,
}) {
  const options = useMemo(() => {
    let list = items;
    if (exclude.length) {
      list = items.filter(function ({ id }) {
        return !exclude.includes(id);
      });
    }
    return list.map(function (item) {
      const result = String(item[label]);
      return {
        value: item.id,
        label: capitalize ? capitalizeFirstLetter(result) : result,
      };
    });
  }, [items]);

  return options;
}

export default useSelectOption;
