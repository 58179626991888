/**
  @description Formats date into a human readable format.
  @name formatDate
  @param {{
    showTime: boolean
    standard: boolean
    endOfDay: boolean
    startOfDay: boolean
    date: number | string
  }}
  @returns Date in string format.
 */
function formatDate({
  date,
  showTime = false,
  standard = false,
  endOfDay = false,
  startOfDay = false,
}) {
  if (!date) {
    return '';
  }
  const cast = ['number', 'string'].includes(typeof date);
  const today = cast ? new Date(date) : date;
  if (endOfDay) {
    const endDay = new Date(date);
    endDay.setHours(23, 59, 59, 999);
    return endDay.toISOString();
  }
  if (startOfDay) {
    const startDay = new Date(date);
    startDay.setHours(0, 0, 0, 0);
    return startDay.toISOString();
  }
  today.setDate(today.getDate());

  const formatted =
    ('0' + today.getDate()).slice(-2) +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    today.getFullYear();

  if (showTime) {
    const time = today.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    return `${formatted} ${time}`;
  }

  if (standard) {
    const year = today.getFullYear();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return formatted;
}

export default formatDate;
