import {
  GET_CUSTOMERS,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
} from 'actions/types';

let cacheList = [];
const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Stores customers array details in redux state.
 * @param {[]} state
 * @param {{}} action
 * @returns {[]}
 */
function customer(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${GET_CUSTOMERS}_FAILURE`) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (type === `${GET_CUSTOMERS}_SUCCESS`) {
    cacheList = payload;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${CREATE_CUSTOMER}_SUCCESS`) {
    cacheList = [...cacheList, payload];
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${UPDATE_CUSTOMER}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = cacheList.map(function (customer) {
      if (customer.id === id) {
        return {
          ...customer,
          ...updatedValues,
        };
      }
      return customer;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${DELETE_CUSTOMER}_SUCCESS`) {
    const { id } = payload;
    const data = cacheList.filter(function (customer) {
      return customer.id !== id;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  return state;
}

export default customer;
