import { endPoints } from 'api/';
import { CREATE_PEOPLE } from 'actions/types';

/**
 * @description Creates people object
 * @param {FormData} payload
 * @returns People object
 */
function createPeople(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_PEOPLE,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const people = await apiClient(endPoints.CREATE_USER, payload);
        return people;
      },
    };
    dispatch(action);
  };
}

export default createPeople;
