import axios from 'axios';
import querystring from 'qs';
import { Buffer } from 'buffer';

import { endPoints } from 'api/';
import { manageCookie } from 'utils/';
import { TOKEN_COOKIE_NAME } from 'constants/general';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_USERNAME = process.env.REACT_APP_WEZZA_API_USERNAME;
const API_PASSWORD = process.env.REACT_APP_WEZZA_API_PASSWORD;

/**
 * @description Entry point for all `HTTP` calls to `API` handles `routing` and structuring request `payloads/query`
 * @param {string} endpoint
 * @param {{} | FormData} payload
 * @param {{} | string} query
 * @returns {{}} AxiosResponse
 */
function apiClient(endpoint, payload, query) {
  const unauthenticatedEndpoints = [
    endPoints.AUTHENTICATE,
    endPoints.CREATE_ACCOUNT,
    endPoints.RESET_PASSWORD,
    endPoints.FORGOT_PASSWORD,
  ];

  if (!unauthenticatedEndpoints.includes(endpoint)) {
    const { token, companyId } =
      manageCookie({ name: TOKEN_COOKIE_NAME }) || {};
    /**
       This is making a lot HTTP of requests timeout.
       According to docs the default is 0 (no timeout)
       https://github.com/axios/axios/blob/main/README.md#request-config
     */
    // axios.defaults.timeout = 2500;
    axios.defaults.withCredentials = false;
    if (companyId) {
      axios.defaults.headers.common['wezza-company'] = companyId;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (endpoint === endPoints.CREATE_USER) {
    if (query) {
      const { companyId } = query;
      if (companyId) {
        axios.defaults.headers.common['wezza-company'] = companyId;
      }
    }
  }

  const credentials = Buffer.from(`${API_USERNAME}:${API_PASSWORD}`).toString(
    'base64',
  );
  const config = {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  };

  // users
  if (endpoint === endPoints.AUTHENTICATE) {
    const url = `${API_ENDPOINT}user/login`;
    return axios.post(url, payload, config);
  }
  if (endpoint === endPoints.GET_USER) {
    const url = `${API_ENDPOINT}user`;
    return axios.get(url);
  }
  if (endpoint === endPoints.GET_USERS) {
    const url = `${API_ENDPOINT}users${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.CREATE_USER) {
    const url = `${API_ENDPOINT}users`;
    return axios.post(url, payload, config);
  }
  if (endpoint === endPoints.UPDATE_USER) {
    const url = `${API_ENDPOINT}users`;
    return axios.put(url, payload);
  }

  // customers
  if (endpoint === endPoints.GET_CUSTOMERS) {
    const url = `${API_ENDPOINT}customers${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.CREATE_CUSTOMER) {
    const config = {
      headers: {
        // Authorization: `Basic ${credentials}`,
        'content-type': 'multipart/form-data',
      },
    };

    const url = `${API_ENDPOINT}customers`;
    return axios.post(url, payload, config);
  }
  if (endpoint === endPoints.UPDATE_CUSTOMER) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const url = `${API_ENDPOINT}customers`;
    return axios.put(url, payload, config);
  }

  // tasks
  if (endpoint === endPoints.CREATE_TASK) {
    const url = `${API_ENDPOINT}tasks`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_TASKS) {
    const url = `${API_ENDPOINT}tasks${query}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_TASK) {
    const url = `${API_ENDPOINT}tasks`;
    return axios.put(url, payload);
  }

  // warehouses
  if (endpoint === endPoints.CREATE_WAREHOUSE) {
    const url = `${API_ENDPOINT}warehouses`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_WAREHOUSES) {
    const url = `${API_ENDPOINT}warehouses${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_WAREHOUSE) {
    const url = `${API_ENDPOINT}warehouses`;
    return axios.put(url, payload);
  }
  // ! TODO should this endpoint be under /warehouse or /inventory route ?
  if (endpoint === endPoints.GET_WAREHOUSE_USAGE) {
    const url = `${API_ENDPOINT}inventory/usage${query}`;
    return axios.get(url);
  }

  // categories
  if (endpoint === endPoints.CREATE_CATEGORY) {
    const url = `${API_ENDPOINT}itemcategories`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_CATEGORIES) {
    const url = `${API_ENDPOINT}itemcategories${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_CATEGORY) {
    const url = `${API_ENDPOINT}itemcategories`;
    return axios.put(url, payload);
  }

  // tags
  if (endpoint === endPoints.CREATE_TAG) {
    const url = `${API_ENDPOINT}tags`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_TAGS) {
    const url = `${API_ENDPOINT}tags${query}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_TAG) {
    const url = `${API_ENDPOINT}tags`;
    return axios.put(url, payload);
  }

  // conditions
  if (endpoint === endPoints.CREATE_CONDITION) {
    const url = `${API_ENDPOINT}statuses`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_CONDITION) {
    const url = `${API_ENDPOINT}statuses${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_CONDITION) {
    const url = `${API_ENDPOINT}statuses`;
    return axios.put(url, payload);
  }

  // items
  if (endpoint === endPoints.CREATE_ITEM) {
    const url = `${API_ENDPOINT}inventory`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.UPLOAD_ITEM) {
    const url = `${API_ENDPOINT}inventory/upload`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_ITEMS) {
    const url = `${API_ENDPOINT}inventory/attributes${query}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.GET_ITEM_UPLOAD) {
    const url = `${API_ENDPOINT}inventory/upload${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ITEM) {
    const url = `${API_ENDPOINT}inventory`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.BULK_UPDATE_ITEM) {
    const url = `${API_ENDPOINT}inventory/bulkupdate`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.UPDATE_ITEM_UPLOAD) {
    const url = `${API_ENDPOINT}inventory/upload`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.GET_STOCK_MOVE) {
    const url = `${API_ENDPOINT}inventory/stockmoves${query}`;
    return axios.get(url);
  }

  // orders
  if (endpoint === endPoints.CREATE_ORDER) {
    const url = `${API_ENDPOINT}orders`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.RETURN_ORDER) {
    const url = `${API_ENDPOINT}orders/returns`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_ORDERS) {
    const url = `${API_ENDPOINT}orders${query}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ORDER) {
    const url = `${API_ENDPOINT}orders`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.BULK_UPDATE_ORDER) {
    const url = `${API_ENDPOINT}orders/bulkupdate`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.ASSIGN_ORDER) {
    const url = `${API_ENDPOINT}orders/assign`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.SHARE_ORDER) {
    const url = `${API_ENDPOINT}orders/share`;
    return axios.post(url, payload, { responseType: 'blob' });
  }
  if (endpoint === endPoints.GET_ASSIGNED_ORDERS) {
    const url = `${API_ENDPOINT}orders/assign${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ASSIGNED_ORDER) {
    const url = `${API_ENDPOINT}orders/assign`;
    return axios.put(url, payload);
  }

  // companies
  if (endpoint === endPoints.CREATE_COMPANY) {
    const url = `${API_ENDPOINT}companies`;
    return axios.post(url, payload, config);
  }
  if (endpoint === endPoints.UPDATE_COMPANY) {
    const url = `${API_ENDPOINT}companies`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.GET_COMPANY) {
    const url = `${API_ENDPOINT}companies${formatQueryString(query)}`;
    return axios.get(url);
  }

  // logs
  if (endpoint === endPoints.GET_LOGS) {
    const url = `${API_ENDPOINT}activitylogs${query}`;
    return axios.get(url);
  }

  // assets
  if (endpoint === endPoints.CREATE_ASSET) {
    const url = `${API_ENDPOINT}assets`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_ASSETS) {
    const url = `${API_ENDPOINT}assets${query}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ASSET) {
    const url = `${API_ENDPOINT}assets`;
    return axios.put(url, payload);
  }
  if (endpoint === endPoints.ASSIGN_ASSET) {
    const url = `${API_ENDPOINT}assets/assign`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_ASSIGNED_ASSETS) {
    const url = `${API_ENDPOINT}assets/assign${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ASSIGNED_ASSET) {
    const url = `${API_ENDPOINT}assets/assign`;
    return axios.put(url, payload);
  }

  // storages
  if (endpoint === endPoints.CREATE_STORAGE) {
    const url = `${API_ENDPOINT}storages`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_STORAGES) {
    const url = `${API_ENDPOINT}storages${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_STORAGE) {
    const url = `${API_ENDPOINT}storages`;
    return axios.put(url, payload);
  }

  // password
  if (endpoint === endPoints.FORGOT_PASSWORD) {
    const url = `${API_ENDPOINT}user/password-forgot`;
    return axios.post(url, payload, config);
  }
  if (endpoint === endPoints.RESET_PASSWORD) {
    const url = `${API_ENDPOINT}user/password-reset`;
    return axios.post(url, payload, config);
  }

  // notification
  if (endpoint === endPoints.GET_NOTIFICATION) {
    const url = `${API_ENDPOINT}notifications${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_NOTIFICATION) {
    const url = `${API_ENDPOINT}notifications`;
    return axios.put(url, payload);
  }

  // attribute
  if (endpoint === endPoints.CREATE_ATTRIBUTE) {
    const url = `${API_ENDPOINT}itemattributes`;
    return axios.post(url, payload);
  }
  if (endpoint === endPoints.GET_ATTRIBUTE) {
    const url = `${API_ENDPOINT}itemattributes${formatQueryString(query)}`;
    return axios.get(url);
  }
  if (endpoint === endPoints.UPDATE_ATTRIBUTE) {
    const url = `${API_ENDPOINT}itemattributes`;
    return axios.put(url, payload);
  }
}

/**
 * @description Formats query object string.
 * @param {{}} query
 * @returns String
 */
function formatQueryString(query) {
  if (typeof query === 'object') {
    return '?' + querystring.stringify(query, { arrayFormat: 'repeat' });
  }
  return query;
}

export default apiClient;
