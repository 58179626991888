import { lazy } from 'react';

import {
  TASK_MODAL,
  USER_MODAL,
  ORDER_MODAL,
  ASSET_MODAL,
  PEOPLE_MODAL,
  STORAGE_MODAL,
  CUSTOMER_MODAL,
  ASSIGN_ASSET_MODAL,
} from 'constants/modal';
import {
  ITEM_PATH,
  ORDER_PATH,
  IMPORT_PATH,
  SUPPORT_PATH,
  DASHBOARD_PATH,
  ITEM_EDIT_PATH,
  NEW_ORDER_PATH,
  EDIT_ORDER_PATH,
  LOCK_SCREEN_PATH,
  NOTIFICATION_PATH,
  RETURN_ORDER_PATH,
  ACCOUNT_SETUP_PATH,
  ACCOUNT_SIGNUP_PATH,
  CONGRATULATIONS_PATH,
} from 'constants/general';
import { ReactComponent as CogIcon } from 'icons/cog.svg';
import { ReactComponent as CodeIcon } from 'icons/code.svg';
import { ReactComponent as ChartIcon } from 'icons/chart.svg';
import { ReactComponent as UsersIcon } from 'icons/users.svg';
import { ReactComponent as TasksIcon } from 'icons/tasks.svg';
import { ReactComponent as CheckIcon } from 'icons/check.svg';
import { ReactComponent as AssignIcon } from 'icons/assign.svg';
import { ReactComponent as PeopleIcon } from 'icons/people.svg';
import { ReactComponent as DesktopIcon } from 'icons/desktop.svg';
import { ReactComponent as StorageIcon } from 'icons/storage.svg';
import { ReactComponent as AddItemIcon } from 'icons/add_item.svg';
import { ReactComponent as AddTaskIcon } from 'icons/add_task.svg';
import { ReactComponent as AddUserIcon } from 'icons/add_user.svg';
import { admin, guest, employee, superadmin } from 'constants/user';
import { ReactComponent as QuestionIcon } from 'icons/question.svg';
import { ReactComponent as CartIcon } from 'icons/shopping_cart.svg';
import { ReactComponent as AddAssetIcon } from 'icons/add_asset.svg';
import { ReactComponent as PlaceOrderIcon } from 'icons/place_order.svg';
import { ReactComponent as AddCustomerIcon } from 'icons/add_customer.svg';
import { ReactComponent as TruckLoadingIcon } from 'icons/truck_loading.svg';

const Tasks = lazy(function () {
  return import('containers/Tasks/Tasks');
});
const Users = lazy(function () {
  return import('containers/Users/Users');
});
const Setup = lazy(function () {
  return import('containers/Setup/Setup');
});
const SignUp = lazy(function () {
  return import('containers/SignUp/SignUp');
});
const People = lazy(function () {
  return import('containers/People/People');
});
const Assets = lazy(function () {
  return import('containers/Assets/Assets');
});
const Settings = lazy(function () {
  return import('containers/Settings/Settings');
});
const Customer = lazy(function () {
  return import('containers/Customer/Customer');
});
const Dashboard = lazy(function () {
  return import('containers/Dashboard/Dashboard');
});
const Item = lazy(function () {
  return import('containers/Inventory/Item/Item');
});
const Return = lazy(function () {
  return import('containers/Order/Return/Return');
});
const SystemLogs = lazy(function () {
  return import('containers/SystemLogs/SystemLogs');
});
const LockScreen = lazy(function () {
  return import('containers/LockScreen/LockScreen');
});
const Import = lazy(function () {
  return import('containers/Inventory/Import/Import');
});
const OrderList = lazy(function () {
  return import('containers/Order/OrderList/OrderList');
});
const OrderForm = lazy(function () {
  return import('containers/Order/OrderForm/OrderForm');
});
const Notification = lazy(function () {
  return import('containers/Notification/Notification');
});
const AssignedAssets = lazy(function () {
  return import('containers/AssignedAssets/AssignedAssets');
});
const Inventory = lazy(function () {
  return import('containers/Inventory/Inventory/Inventory');
});
const Congratulations = lazy(function () {
  return import('containers/Congratulations/Congratulations');
});

const borderRadius = '5px';

const borderTopRadius = {
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
};

const borderBottomRadius = {
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
};

export const inventoryMenus = [
  {
    modal: null,
    name: 'addItem',
    Icon: AddItemIcon,
    caption: 'Add New Item',
    redirect: `${ITEM_PATH}#general`,
  },
  {
    name: 'placeOrder',
    modal: ORDER_MODAL,
    Icon: PlaceOrderIcon,
    redirect: NEW_ORDER_PATH,
    caption: 'Create New Order',
  },
  {
    name: 'addTask',
    Icon: AddTaskIcon,
    modal: TASK_MODAL,
    caption: 'Add A Task',
  },
  {
    name: 'addCustomer',
    Icon: AddCustomerIcon,
    modal: CUSTOMER_MODAL,
    caption: 'Add Customer',
  },
  {
    name: 'addUser',
    Icon: AddUserIcon,
    modal: USER_MODAL,
    caption: 'Add User',
  },
];

export const assetsMenus = [
  {
    name: 'addAsset',
    Icon: AddAssetIcon,
    modal: ASSET_MODAL,
    caption: 'Add New Asset',
  },
  {
    Icon: AssignIcon,
    name: 'assignAsset',
    caption: 'Assign Asset',
    modal: ASSIGN_ASSET_MODAL,
  },
  {
    Icon: PeopleIcon,
    name: 'addPerson',
    modal: PEOPLE_MODAL,
    caption: 'Add New Person',
  },
  {
    Icon: StorageIcon,
    name: 'addStorage',
    modal: STORAGE_MODAL,
    caption: 'Add Storage',
  },
];

const menuList = [
  {
    exact: true,
    type: 'menu',
    buttonStyle: {
      borderRadius,
    },
    icon: ChartIcon,
    name: 'dashboard',
    path: DASHBOARD_PATH,
    caption: 'Dashboard',
    component: Dashboard,
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin, guest, employee],
  },
  {
    type: 'title',
    name: 'inventorySection',
    caption: 'Inventory Management',
  },
  {
    exact: true,
    type: 'menu',
    name: 'inventory',
    path: '/inventory',
    component: Inventory,
    caption: 'Inventory',
    buttonStyle: {
      ...borderTopRadius,
    },
    icon: TruckLoadingIcon,
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin, employee],
  },
  {
    exact: true,
    type: 'menu',
    name: 'orders',
    icon: CartIcon,
    path: ORDER_PATH,
    caption: 'Orders',
    component: OrderList,
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin, guest, employee],
  },
  {
    exact: true,
    type: 'menu',
    name: 'tasks',
    icon: TasksIcon,
    caption: 'Tasks',
    component: Tasks,
    path: '/inventory/tasks',
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin, guest, employee],
  },
  {
    exact: true,
    type: 'menu',
    icon: UsersIcon,
    name: 'customers',
    component: Customer,
    caption: 'Customers',
    path: '/inventory/customers',
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin, employee],
  },
  {
    exact: true,
    type: 'menu',
    name: 'users',
    icon: UsersIcon,
    component: Users,
    caption: 'Users',
    path: '/inventory/users',
    buttonStyle: {
      ...borderBottomRadius,
    },
    topMenuButtons: inventoryMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    type: 'title',
    name: 'assetsSection',
    caption: 'Assets Management',
  },
  {
    exact: true,
    type: 'menu',
    name: 'assets',
    path: '/assets',
    caption: 'Assets',
    component: Assets,
    icon: DesktopIcon,
    buttonStyle: {
      ...borderTopRadius,
    },
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    exact: true,
    type: 'menu',
    icon: CheckIcon,
    name: 'assignedAssets',
    path: '/assets/assigned',
    component: AssignedAssets,
    caption: 'Assigned Assets',
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    exact: true,
    type: 'menu',
    name: 'people',
    icon: UsersIcon,
    component: People,
    caption: 'People',
    path: '/assets/people',
    buttonStyle: {
      ...borderBottomRadius,
    },
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    type: 'title',
    caption: 'General',
    name: 'generalSection',
  },
  {
    exact: true,
    type: 'menu',
    icon: CogIcon,
    name: 'settings',
    caption: 'Settings',
    component: Settings,
    path: '/general/settings',
    buttonStyle: {
      ...borderTopRadius,
    },
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    exact: true,
    name: 'logs',
    type: 'menu',
    icon: CodeIcon,
    caption: 'Logs',
    path: '/general/logs',
    component: SystemLogs,
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin],
  },
  {
    exact: true,
    type: 'menu',
    name: 'support',
    caption: 'Support',
    icon: QuestionIcon,
    path: SUPPORT_PATH,
    buttonStyle: {
      ...borderBottomRadius,
    },
    topMenuButtons: assetsMenus,
    eligibleUsers: [superadmin, admin, guest, employee],
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true, // hide this from 'SideNav' but render component
    name: 'setup',
    path: ACCOUNT_SETUP_PATH,
    caption: 'Setup Progress',
    buttonStyle: {
      borderRadius,
    },
    component: Setup,
    topMenuButtons: [], // render progress bar ?
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true, // hide this from 'SideNav' but render component
    name: 'congratulations',
    path: CONGRATULATIONS_PATH,
    caption: 'Congratulations',
    buttonStyle: {
      borderRadius,
    },
    component: Congratulations,
    topMenuButtons: inventoryMenus,
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true,
    name: 'signup',
    buttonStyle: {},
    caption: 'SignUp',
    component: SignUp,
    topMenuButtons: [],
    path: ACCOUNT_SIGNUP_PATH,
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    name: 'lock',
    hidden: true,
    buttonStyle: {},
    topMenuButtons: [],
    caption: 'LockScreen',
    component: LockScreen,
    path: LOCK_SCREEN_PATH,
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true, // hide this from 'SideNav' but render component
    buttonStyle: {},
    name: 'notification',
    path: NOTIFICATION_PATH,
    caption: 'Notification',
    component: Notification,
    topMenuButtons: inventoryMenus,
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true, // hide this from 'SideNav' but render component
    buttonStyle: {},
    name: 'import',
    path: IMPORT_PATH,
    caption: 'Import',
    component: Import,
    topMenuButtons: inventoryMenus,
  },
  {
    icon: null,
    exact: true,
    type: 'menu',
    hidden: true, // hide this from 'SideNav' but render component
    buttonStyle: {},
    name: 'returned',
    component: Return,
    caption: 'Returned',
    path: RETURN_ORDER_PATH,
    topMenuButtons: inventoryMenus,
  },
  ...[ITEM_EDIT_PATH, ITEM_PATH].map(function (path) {
    return {
      path,
      icon: null,
      exact: true,
      type: 'menu',
      name: 'item',
      hidden: true, // hide this from 'SideNav' but render component
      buttonStyle: {},
      component: Item,
      caption: 'Item',
      topMenuButtons: inventoryMenus,
      eligibleUsers: [superadmin, admin, employee],
    };
  }),
  ...[EDIT_ORDER_PATH, NEW_ORDER_PATH].map(function (path) {
    return {
      path,
      icon: null,
      exact: true,
      type: 'menu',
      name: 'item',
      hidden: true, // hide this from 'SideNav' but render component
      buttonStyle: {},
      caption: 'OrderForm',
      component: OrderForm,
      topMenuButtons: inventoryMenus,
      eligibleUsers: [admin, employee, superadmin],
    };
  }),
];

export default menuList;
