import styled from 'styled-components';

export const StyledTileBody = styled.ul`
  height: 290px;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  overflow-x: hidden;
  padding-right: 15px;
  ${({ theme }) => theme.scrollbar};
`;

export const StyledTileItem = styled.li`
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const StyledLabel = styled.label`
  color: ${({ color }) => color};
  background-color: ${({ backColor }) => backColor};

  margin: 0 10px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;

  &:last-child {
  }

  :not(:first-of-type) {
    margin-left: auto;
  }
`;

export const StyledItemNameLabel = styled.label`
  color: ${({ color }) => color};

  margin: 0 10px;
  max-width: 50%;
  min-width: 50%;
  font-size: 12px;
  overflow: hidden;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledTopOrderItem = styled.div`
  display: flex;
  justify-content: space-between;
`;
