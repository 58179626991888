import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from 'reducers';
import { manageCookie } from 'utils/';
import apiMiddleware from 'middleware/apiMiddleware';
import { TOKEN_COOKIE_NAME } from 'constants/general';
import authMiddleware from 'middleware/authMiddleware';

function createConfiguredStore() {
  const initialState = {};
  const enhancers = [];
  const history = createBrowserHistory();
  const middleware = [thunk, apiMiddleware, authMiddleware];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer(), initialState, composedEnhancers);

  store.subscribe(function () {
    const { isLoggedOut } = store.getState();
    if (isLoggedOut && window.location.pathname.indexOf('login') === -1) {
      manageCookie({ name: TOKEN_COOKIE_NAME, clear: true });
    }
  });
  return {
    store,
    history,
  };
}

const { store, history } = createConfiguredStore();

export { history, createConfiguredStore };

export default store;
