import { func, object } from 'prop-types';
import React, { useState, useEffect } from 'react';

import { active } from 'constants/status';
import { employee } from 'constants/user';
import { FlexCol, FlexRow } from 'components/Layout/';
import { PhotoInput, TextInput } from 'components/Inputs/';
import { validateEmail, capitalizeFirstLetter } from 'utils/';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';

/**
 * @param {{
 * onSubmit : Function
 * initialValue: Function
 * onModalClose: Function }} param
 */
function PeopleForm({ onSubmit, initialValue, onModalClose }) {
  const {
    phone,
    email,
    photo,
    last_name: lastName,
    first_name: firstName,
  } = initialValue || {};
  const [state, setState] = useState({
    phone,
    email,
    lastName,
    firstName,
  });
  const [errors, setErrors] = useState({
    phone: '',
    email: '',
    lastName: '',
    firstName: '',
  });
  const [profilePhoto, setProfilePhoto] = useState(photo);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onSubmitPeople() {
    setIsSubmitting(true);
    const hasAccess = false;
    const isValidForm = validatePeopleForm();
    if (isValidForm) {
      const { email, phone, lastName, firstName } = state;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('status', active);
      formData.append('usertype', employee);
      formData.append('last_name', lastName);
      formData.append('photo', profilePhoto);
      formData.append('first_name', firstName);
      formData.append('has_access', hasAccess);
      onSubmit(formData);
    }
  }

  function validatePeopleForm() {
    let isFormValid = true;
    let errorObject = { ...errors };
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setErrors(errorObject);
    return isFormValid;
  }

  useEffect(() => {
    if (isSubmitting) {
      validatePeopleForm();
    }
  }, [state, isSubmitting]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="firstName"
                inputType="text"
                inputId="firstName"
                caption="First Name"
                value={state.firstName}
                onChange={onInputChange}
                placeholder="Enter first name"
                errorMessage={errors.firstName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="lastName"
                inputType="text"
                inputId="lastName"
                caption="Last Name"
                value={state.lastName}
                onChange={onInputChange}
                placeholder="Enter last name"
                errorMessage={errors.lastName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                caption="Phone"
                inputType="text"
                value={state.phone}
                onChange={onInputChange}
                errorMessage={errors.phone}
                placeholder="e.g +254712345678"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="email"
                inputId="email"
                inputType="text"
                value={state.email}
                caption="Email Address"
                onChange={onInputChange}
                errorMessage={errors.email}
                placeholder="Enter email address"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <PhotoInput
                id="profilePicture"
                imageUri={profilePhoto}
                caption="Profile Picture"
                onChange={setProfilePhoto}
                dataTestId="profilePicture"
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Add"
          icon={<ArrowIcon />}
          className="modal-button"
          onClick={onSubmitPeople}
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

PeopleForm.propTypes = {
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default PeopleForm;
