import { endPoints } from 'api/';
import { getItems } from 'actions/item';
import { UPDATE_BULK_ITEM } from 'actions/types';

/**
 * Bulk updates inventory items.
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 */
function update({ payload, companyId }) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_BULK_ITEM,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const item = await apiClient(endPoints.BULK_UPDATE_ITEM, payload);
        dispatch(getItems({ companyId }));
        return item;
      },
    };
    dispatch(action);
  };
}

export default update;
