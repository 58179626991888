export const TAG_MODAL = 'TAG_MODAL';
export const USER_MODAL = 'USER_MODAL';
export const TASK_MODAL = 'TASK_MODAL';
export const ORDER_MODAL = 'ORDER_MODAL';
export const ASSET_MODAL = 'ASSET_MODAL';
export const EMAIL_MODAL = 'EMAIL_MODAL';
export const PEOPLE_MODAL = 'PEOPLE_MODAL';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const STORAGE_MODAL = 'STORAGE_MODAL';
export const CLEAR_CONFIRM = 'CLEAR_CONFIRM';
export const COMPANY_MODAL = 'COMPANY_MODAL';
export const CUSTOMER_MODAL = 'CUSTOMER_MODAL';
export const CATEGORY_MODAL = 'CATEGORY_MODAL';
export const CONDITION_MODAL = 'CONDITION_MODAL';
export const WAREHOUSE_MODAL = 'WAREHOUSE_MODAL';
export const ORDER_INFO_MODAL = 'ORDER_INFO_MODAL';
export const ASSET_INFO_MODAL = 'ASSET_INFO_MODAL';
export const ASSIGN_ASSET_MODAL = 'ASSIGN_ASSET_MODAL';
export const USER_PROFILE_MODAL = 'USER_PROFILE_MODAL';
export const STORAGE_LIST_MODAL = 'STORAGE_LIST_MODAL';
export const GENERATE_SKU_MODAL = 'GENERATE_SKU_MODAL';
export const WAREHOUSE_LIST_MODAL = 'WAREHOUSE_LIST_MODAL';
