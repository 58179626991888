import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_CUSTOMERS } from 'actions/types';

/**
 * @description Get a list of all customers
 * @param {{
 *  companyId : string,
 *  lowerDate : string,
 *  upperDate : string,
 * }} params
 * @returns Array of customer objects
 */
function query({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "${active}"${dateFilter}}`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_CUSTOMERS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const customer = await apiClient(endPoints.GET_CUSTOMERS, {}, query);
        return customer;
      },
    };
    dispatch(action);
  };
}

export default query;
