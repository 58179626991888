import { capitalizeFirstLetter } from 'utils/';

/**
 * @param {string} id
 * @param {string[]} tags
 */
function getTagById(id, tags) {
  const tag = tags.find(function (tag) {
    return tag.id === id;
  });
  return capitalizeFirstLetter(tag?.tagname);
}

export default getTagById;
