import { endPoints } from 'api/';
import { DELETE_ITEM, UPDATE_ITEM } from 'actions/types';

/**
 * Updates existing or uploaded item object.
 * This will also act as `delete` action by `disabling` status.
 * @param {{
 * id : string
 * payload : {}
 * companyId : string
 * uploaded : boolean
 * deleting : boolean
 * }} param
 */
function updateItem({
  id,
  payload,
  source = '',
  uploaded = false,
  deleting = false,
}) {
  const actionType = deleting ? DELETE_ITEM : UPDATE_ITEM;
  const url = uploaded ? endPoints.UPDATE_ITEM_UPLOAD : endPoints.UPDATE_ITEM;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const item = await apiClient(url, payload, id);
        return {
          ...item,
          data: {
            ...item.data,
            response: {
              ...item.data.response,
              source,
            },
          },
        };
      },
    };

    dispatch(action);
  };
}

export default updateItem;
