import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { CONDITION_MODAL } from 'constants/modal';
import Condition from 'components/Condition/Condition';
import { createCondition, updateCondition } from 'actions/condition/';

function ConditionModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: conditions, isLoading } = useSelector(
    ({ condition }) => condition,
  );
  const isOpen = modal?.type === CONDITION_MODAL;
  const modalCaption = 'Manage Conditions';

  function onModalClose() {
    dispatch(clearModal(CONDITION_MODAL));
  }

  /**
   * @param {{}} conditionObj
   * @param {boolean} isEditing
   * @param {boolean} isDeleting
   */
  function onSubmit(conditionObj, isEditing, isDeleting) {
    const { id: conditionId } = conditionObj;
    const { company_id: companyId } = user;
    if (isEditing) {
      const payload = {
        ...conditionObj,
        company_id: companyId,
      };
      dispatch(updateCondition(conditionId, payload, isDeleting));
    } else {
      const payload = {
        ...conditionObj,
        company_id: companyId,
      };
      dispatch(createCondition(payload));
    }
  }

  return (
    <Modal
      modalTop={5}
      modalLeft={30}
      isOpen={isOpen}
      modalWidth={40}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Condition
        onSubmit={onSubmit}
        isLoading={isLoading}
        initialValue={conditions}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default ConditionModal;
