import { v4 } from 'uuid';
/**
 * @description Returns a random generated string.
 * @param {{
 * length: number,
 * prefix: string,
 * upperCase: boolean,
 * }} options
 */
function getRandomString({ length = 5, prefix = '', upperCase = false } = {}) {
  let text = v4().replace(/-/g, '').slice(0, length);
  if (prefix) {
    text = `${prefix}${text}`;
  }
  return upperCase ? text.toUpperCase() : text;
}

export default getRandomString;
