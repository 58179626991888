import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getItems } from 'actions/item/';
import { getWarehouses } from 'actions/warehouses/';

function useWareHouseItem() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const { data: items } = useSelector(({ item }) => item);
  const { data: warehouses, isLoadingWarehouses } = useSelector(
    ({ warehouses }) => warehouses,
  );

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;
      dispatch(getWarehouses({ companyId }));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;
      /**
       * Inventory items are queried by warehouse-id,
       * so delaying this dispatch to wait for warehouses to be
       * loaded first, then dispatch /getItems.
       */
      if (!isLoadingWarehouses && warehouses?.length > 0) {
        dispatch(getItems({ companyId }));
      }
    }
  }, [user, dispatch, warehouses, isLoadingWarehouses]);

  return { items, warehouses };
}

export default useWareHouseItem;
