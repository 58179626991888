import { endPoints } from 'api/';
import { CREATE_ITEM } from 'actions/types';

/**
 * @description Creates item object
 * @param {{}} payload
 */
function createItem(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_ITEM,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const item = await apiClient(endPoints.CREATE_ITEM, payload);
        return item;
      },
    };
    dispatch(action);
  };
}

export default createItem;
