import styled, { css } from 'styled-components';

import { white, maroon } from 'constants/color';

export const StyledWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${white};
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 10px;
  }
`;

export const StyledHeader = styled.h1``;

export const StyledArrow = styled.span`
  cursor: pointer;
  margin-left: 10px;
  svg {
    width: 10px;
    height: 10px;
    transition: transform 0.3s ease;
    fill: ${({ theme }) => theme.primaryColor};
    ${function ({ $open }) {
      return $open
        ? css`
            transform: rotate(270deg);
          `
        : css`
            transform: rotate(90deg);
          `;
    }}
  }
  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-left: 5px;
  }
`;

export const StyledSelect = styled.select`
  border: none;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.primaryColor};
  &:focus {
    outline: none;
  }
`;

export const StyledInput = styled.input`
  --primary: ${({ theme }) => theme.primaryColor};

  border: none;
  min-width: 50px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  color: var(--primary);
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

export const StyledTableData = styled.td`
  height: 40px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  padding-left: 20px;
  font-weight: normal;
  ${function ({ $sub }) {
    return (
      $sub &&
      css`
        padding-left: 60px;
      `
    );
  }}
  /* Mobile Devices */
  @media (max-width: 480px) {
    ${function ({ $first }) {
      return (
        $first &&
        css`
          width: auto;
          min-width: 150px;
        `
      );
    }}
  }
`;

export const StyledTableRow = styled.tr`
  --primaryColor: ${({ theme }) => theme.primaryColor};

  &:not(:first-child) {
    ${StyledTableData} {
      border-top: 1px solid var(--primaryColor);
    }
  }
  &:last-child {
    ${StyledTableData} {
      border-bottom: 1px solid var(--primaryColor);
    }
  }
`;

export const StyledMessage = styled.p`
  font-size: 12px;
  font-size: 14px;
  font-weight: 400;
  color: ${maroon};
  margin: 2px 0 0 0;
  padding: 5px 10px;
  margin: 5px 0 10px 5px;
  background-color: #f8d7da;
`;

export const StyledButtonRow = styled.div`
  width: 100%;
  display: flex;
  div:nth-child(2) {
    margin-left: 20px;
  }
`;

export const StyledButtonWrapper = styled.div`
  width: 20%;
  min-height: 1px;
  position: relative;
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 50%;
    padding-left: 0;
    margin-top: 10px;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;

export const StyledOption = styled.option`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.primaryColor};
`;

export const StyledWareHouseInputWrapper = styled.div`
  margin-right: 5px;
`;
