import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { ASSET_MODAL } from 'constants/modal';
import { createAsset, updateAsset } from 'actions/assets/';
import AssetForm from 'components/Assets/AssetForm/AssetForm';

function AssetModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: tags } = useSelector(({ tag }) => tag);
  const company = useSelector(({ company }) => company);
  const { data: storages } = useSelector(({ storages }) => storages);
  const { data: conditions } = useSelector(({ condition }) => condition);
  const { data: categories } = useSelector(({ categories }) => categories);
  const isOpen = modal?.type === ASSET_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const actionType = isEditing ? 'Update' : 'Create';
  const modalCaption = `${actionType} Inventory Asset`;
  const { initialValue } = modal;

  const settings = useMemo(() => {
    return {
      units: company?.other_details?.quantity_units,
      maxQuantity: company?.package_details?.max_quantity,
    };
  }, [company]);

  function onModalClose() {
    dispatch(clearModal(ASSET_MODAL));
  }

  /**
   * @param {FormData} payload
   */
  function onSubmit(payload) {
    const { company_id: companyId } = user;
    if (isEditing) {
      const { id: assetId } = initialValue || {};
      payload.append('id', assetId);
      dispatch(updateAsset(assetId, payload, companyId, false));
    } else {
      payload.append('company_id', companyId);
      dispatch(createAsset(payload, companyId));
    }
  }

  return (
    <Modal
      modalTop={5}
      modalLeft={5}
      isOpen={isOpen}
      modalWidth={90}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <AssetForm
        tags={tags}
        onSubmit={onSubmit}
        storages={storages}
        conditions={conditions}
        categories={categories}
        initialValue={initialValue}
        onModalClose={onModalClose}
        quantityUnits={settings.units}
        maxQuantity={settings.maxQuantity}
      />
    </Modal>
  );
}

export default AssetModal;
