import { endPoints } from 'api/';
import { UPDATE_BULK_ORDER } from 'actions/types';

/**
 * Bulk update orders.
 * @param {{}} payload
 */
function update({ payload }) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_BULK_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const order = await apiClient(endPoints.BULK_UPDATE_ORDER, payload);
        return order;
      },
    };
    dispatch(action);
  };
}

export default update;
