import { endPoints } from 'api/';
import { GET_ITEM_HISTORY } from 'actions/types';

/**
 * @description Get a list of a specific inventory logs
 * @param {{
 *   companyId: string,
 *   inventoryId: string
 * }} params
 */
function getItemHistory({ companyId, inventoryId }) {
  const query = `?where={"company_id": "${companyId}", "reference_table": "inventory", "reference_id": "${inventoryId}"}`;

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_ITEM_HISTORY,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const logs = await apiClient(endPoints.GET_LOGS, {}, query);
        return logs;
      },
    };
    dispatch(action);
  };
}

export default getItemHistory;
