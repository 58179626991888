import { endPoints } from 'api/';
import { formatDate } from 'utils/';
import { ASC } from 'constants/general';
import { GET_ORDERS } from 'actions/types';

/**
 * @description Query orders
 * @param {{
 *   companyId : string,
 *   lowerDate : string,
 *   upperDate : string,
 * }} params
 * @returns Array of order objects
 */
function query({ companyId, lowerDate, upperDate }) {
  let dateFilter = '';
  const hasDate = !!lowerDate && !!upperDate;
  if (hasDate) {
    const endDate = formatDate({ endOfDay: true, date: upperDate });
    const startDate = formatDate({ startOfDay: true, date: lowerDate });
    dateFilter = `,"createdat": "btwn::${startDate},${endDate}"`;
  }
  const query = `?where={"company_id": "${companyId}"${dateFilter}}&order={"createdat":"${ASC}"}`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_ORDERS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const orders = await apiClient(endPoints.GET_ORDERS, {}, query);
        return orders;
      },
    };
    dispatch(action);
  };
}

export default query;
