import {
  bool,
  func,
  node,
  array,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import React from 'react';

import {
  white,
  mediumGrey,
  secondaryDark,
  primary as primaryColor,
  invalid as invalidColor,
} from 'constants/color';
import {
  StyledLabel,
  StyledSelect,
  StyledAsterisk,
  InputErrorLabel,
  StyledFormGroup,
  StyledSrOnlyLabel,
  StyledDescription,
} from 'components/Inputs/SelectInput/SelectInput.styled';

/**
 *  @param {{
 *  options: [],
 *  name: string,
 *  isMulti: bool,
 *  inputId: string,
 *  caption: string,
 *  isInvalid: bool,
 *  isLoading: bool,
 *  isRequired: bool,
 *  tabIndex: number,
 *  disabled: boolean,
 *  isClearable: bool,
 *  showCaption: bool,
 *  onChange: Function,
 *  isBorderLess: bool,
 *  isSearchable: bool,
 *  value: string | {},
 *  isTableFilter: bool,
 *  placeholder: string,
 *  errorMessage: string,
 *  ariaLabelledBy: string,
 *  defaultValue: string | {},
 *  menuPortalTarget: HTMLElement | null,
 *  }} param
 */
function SelectInput({
  name,
  value,
  inputId,
  caption,
  options,
  onChange,
  tabIndex,
  isLoading,
  placeholder,
  isClearable,
  isSearchable,
  errorMessage,
  defaultValue,
  ariaLabelledBy,
  isMulti = false,
  disabled = false,
  description = '',
  isInvalid = false,
  marginBottom = 15,
  showCaption = true,
  isRequired = false,
  isBorderLess = false,
  isTableFilter = false,
  menuPortalTarget = null,
}) {
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const baseStyles = {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '1.42857143',
    fontSize: isTableFilter ? '11px' : '14px',
    color: disabled ? '#a0a0a0' : primaryColor,
  };
  const isMainControl = !isTableFilter && !isBorderLess;
  const selectColor = hasError ? invalidColor : primaryColor;
  const currentColor = disabled ? '#d0d0d0' : selectColor;
  const borderColor = isBorderLess ? mediumGrey : selectColor;
  const border = `1px solid ${borderColor}`;
  const customStyles = {
    control: function (base) {
      let hover = {};
      let props = {
        minHeight: '35px',
      };
      if (disabled && isBorderLess) {
        props = {
          ...props,
          backgroundColor: white,
        };
      }
      if (isTableFilter || isBorderLess) {
        props = {
          ...props,
          padding: 0,
          height: '25px',
          minHeight: '25px',
        };
      }
      if (isBorderLess) {
        props = {
          ...props,
          border: 'none',
          borderBottom: border,
        };
        hover = {
          ...hover,
          borderBottom: `1px solid ${selectColor}`,
        };
      } else {
        props = {
          ...props,
          border: `1px solid ${currentColor}`,
        };
      }
      return {
        ...base,
        ...props,
        ...baseStyles,
        borderRadius: 0,
        '&:hover': hover,
        boxShadow: 'none',
        color: primaryColor,
      };
    },
    option: function (provided, state) {
      return {
        ...provided,
        ...baseStyles,
        color: state.isFocused && secondaryDark,
        paddingLeft: isTableFilter ? '5px' : '7px',
        backgroundColor: state.isFocused && '#f0f0f0',
      };
    },
    singleValue: function (provided) {
      const props = {};
      if (isTableFilter) {
        props.top = '10px';
        props.paddingTop = '2px';
      }
      return {
        ...provided,
        ...baseStyles,
        ...props,
      };
    },
    dropdownIndicator: function (base) {
      const props = {};
      if (isTableFilter) {
        props.padding = 2;
      }
      if (isMainControl) {
        props.paddingTop = 6;
        props.paddingBottom = 0;
      }
      return {
        ...base,
        ...props,
        color: currentColor,
        '&:hover': {
          color: selectColor,
        },
        display: isBorderLess ? 'none' : 'block',
      };
    },
    clearIndicator: function (base) {
      const props = {};
      if (isBorderLess) {
        props.display = 'none';
      }
      if (isTableFilter) {
        props.paddingTop = '2px';
        props.paddingRight = '2px';
      }
      if (isMainControl) {
        props.padding = 4;
      }
      return {
        ...base,
        ...props,
        color: selectColor,
        '&:hover': {
          color: selectColor,
        },
      };
    },
    indicatorSeparator: function (base) {
      const props = {};
      if (isTableFilter) {
        props.marginTop = '5px';
        props.marginBottom = '10px';
      }
      return {
        ...base,
        ...props,
        backgroundColor: currentColor,
        '&:hover': {
          backgroundColor: selectColor,
        },
        display: isBorderLess ? 'none' : 'block',
      };
    },
    placeholder: function (base) {
      const props = {};
      if (isTableFilter) {
        props.top = '10px';
      }
      return {
        ...base,
        ...props,
        fontWeight: 500,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        lineHeight: '1.42857143',
        alignItems: 'flex-start',
        textOverflow: 'ellipsis',
        fontSize: isTableFilter ? '12px' : '14px',
      };
    },
    valueContainer: function (base) {
      const props = {
        padding: '0px 6px',
      };
      if (isBorderLess) {
        props.padding = '0px 0px';
      }
      if (isTableFilter) {
        props.paddingBottom = '2px';
      }
      return {
        ...base,
        ...props,
      };
    },
    input: function (base) {
      return {
        ...base,
        top: 0,
        margin: 0,
        paddingTop: 0,
        fontSize: '14px',
        color: primaryColor,
      };
    },
    menuList: function (base) {
      return {
        ...base,
        padding: 0,
        marginTop: 0,
        zIndex: 9999,
        borderRadius: 0,
        boxShadow: 'none',
        border: `1px solid ${currentColor}`,
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: primaryColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: secondaryDark,
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: mediumGrey,
        },
      };
    },
    menu: function (provided) {
      return {
        ...provided,
        border: 'none',
        boxShadow: 'none',
      };
    },
    groupHeading: function (base) {
      return {
        ...base,
        fontSize: '13px',
        textTransform: 'uppercase',
      };
    },
  };

  return (
    <StyledFormGroup
      $isBorderLess={isBorderLess}
      $marginBottom={marginBottom}
      $isTableFilter={isTableFilter}
    >
      {isTableFilter || isBorderLess || !showCaption ? (
        <StyledSrOnlyLabel id={ariaLabelledBy}>{caption}</StyledSrOnlyLabel>
      ) : (
        <StyledLabel id={ariaLabelledBy}>{caption}</StyledLabel>
      )}
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      {description ? (
        <StyledDescription>{description}</StyledDescription>
      ) : null}
      <StyledSelect
        name={name}
        id={inputId}
        value={value}
        options={options}
        isMulti={isMulti}
        tabIndex={tabIndex}
        onChange={onChange}
        isDisabled={disabled}
        styles={customStyles}
        isLoading={isLoading}
        isInvalid={invalidInput}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        $isTableFilter={isTableFilter}
        aria-labelledby={ariaLabelledBy}
        menuPortalTarget={menuPortalTarget}
      />
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledFormGroup>
  );
}

SelectInput.propTypes = {
  name: string,
  onChange: func,
  options: array,
  disabled: bool,
  inputId: string,
  caption: string,
  isInvalid: bool,
  isLoading: bool,
  isRequired: bool,
  tabIndex: number,
  isClearable: bool,
  showCaption: bool,
  isSearchable: bool,
  isBorderLess: bool,
  isTableFilter: bool,
  placeholder: string,
  description: string,
  errorMessage: string,
  ariaLabelledBy: string,
  value: oneOfType([string, object]),
  defaultValue: oneOfType([string, object]),
  menuPortalTarget: oneOfType([node, object]),
};

export default SelectInput;
