import { endPoints } from 'api/';
import { UPDATE_PEOPLE, DELETE_PEOPLE } from 'actions/types';

/**
 * @description Updates people object. This will also act as `delete` action by `disabling` status.
 * @param {string} peopleId
 * @param {FormData} payload
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns People object without token
 */
function updatePeople(peopleId, payload, isDeleting = false) {
  const actionType = isDeleting ? DELETE_PEOPLE : UPDATE_PEOPLE;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const people = await apiClient(
          endPoints.UPDATE_USER,
          payload,
          peopleId,
        );
        return people;
      },
    };
    dispatch(action);
  };
}

export default updatePeople;
