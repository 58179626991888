import { endPoints } from 'api/';
import { CREATE_ORDER } from 'actions/types';
import { ASSIGNED } from 'constants/inventory';
import { assignOrder, getOrders } from 'actions/order/';

/**
 * @description Creates order object
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @returns Order object
 */
function create(payload, companyId) {
  const { notes, userId, returnDate, deliveryDate, ...rest } = payload;
  let props = {};
  const data = { ...rest, notes };
  if (returnDate) {
    props = { return_date: returnDate };
  }
  if (deliveryDate) {
    props = { ...props, delivery_date: deliveryDate };
  }
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const order = await apiClient(endPoints.CREATE_ORDER, data);
        dispatch(getOrders({ companyId }));
        if (userId && order.status === 200) {
          const {
            data: { response },
          } = order;
          const data = {
            notes,
            ...props,
            user_id: userId,
            status: ASSIGNED,
            order_id: response.id,
            company_id: companyId,
            date_assigned: new Date().getTime(),
          };
          dispatch(assignOrder(data));
        }
        return order;
      },
    };
    dispatch(action);
  };
}

export default create;
