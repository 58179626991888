import { endPoints } from 'api/';
import { CREATE_CUSTOMER } from 'actions/types';

/**
 * @description Creates customer object
 * @param {{}} payload
 * @returns Customer object
 */
function create(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_CUSTOMER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const customer = await apiClient(endPoints.CREATE_CUSTOMER, payload);
        return customer;
      },
    };
    dispatch(action);
  };
}

export default create;
