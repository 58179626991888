import React, { useMemo, useState, useEffect } from 'react';

import {
  CheckBox,
  TextArea,
  TextInput,
  SelectInput,
  ToggleSwitch,
} from 'components/Inputs/';
import { capitalizeFirstLetter } from 'utils/';
import {
  StyledList,
  StyledStatus,
  StyledListItem,
  StyledErrorLabel,
  StyledSubHeading,
} from 'components/Order/General/General.styled';
import { SALE, LEASE } from 'constants/inventory';
import { FlexCol, FlexRow } from 'components/Layout/';
import { useSelectOption, useSelectValue } from 'hooks/';
import DatePicker from 'components/DatePicker/DatePicker';

function General({
  state,
  users,
  userId,
  errors,
  setState,
  customers,
  isReadOnly,
  customerId,
  warehouses,
  statusName,
  onInputChange,
  orderWarehouses,
}) {
  const isLease = state.type === LEASE;
  const requireDeliveryDate = !!state.assignee;
  const [assigneeOption, setAssigneeOption] = useState(null);
  const [customerOption, setCustomerOption] = useState(null);
  const [warehouseOption, setWarehouseOption] = useState(null);
  const includeLeasePrice = isLease && state.includeLeasePrice;
  const warehouseOptions = useSelectOption({ items: warehouses });
  const assigneeOptions = useMemo(() => {
    return users?.map(function ({ id, name }) {
      return { value: id, label: name };
    });
  }, [users]);
  const customerOptions = useMemo(() => {
    return customers?.map(function ({ id, name }) {
      return { value: id, label: name };
    });
  }, [customers]);
  useSelectValue({
    labels: orderWarehouses,
    options: warehouseOptions,
    onSelectChange: onWarehouseChange,
    dependency: [orderWarehouses, warehouseOptions],
  });
  const warehouseInput = (
    <FlexCol flexWidth={4}>
      <SelectInput
        isMulti
        isRequired
        name="warehouse"
        marginBottom={5}
        isLoading={false}
        isClearable={true}
        caption="Warehouse"
        isSearchable={true}
        disabled={isReadOnly}
        value={warehouseOption}
        ariaLabelledBy="warehouse"
        options={warehouseOptions}
        onChange={onWarehouseChange}
        placeholder="Select warehouse"
        errorMessage={errors.warehouses}
      />
    </FlexCol>
  );

  function onIncludeLeasePriceChange() {
    setState({
      ...state,
      includeLeasePrice: !includeLeasePrice,
    });
  }

  /**
   * @param {Date} date
   * @param {string} name
   */
  function onDateChange(name, date) {
    const isDate = date && typeof date === 'object';
    const value = isDate ? new Date(date).getTime() : '';
    setState({
      ...state,
      [name]: value,
    });
  }

  /**
   * @param {string} value
   */
  function onOrderTypeCheckChange(value) {
    const type = state.type === value ? '' : value;
    setState({
      ...state,
      type,
    });
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   * @param {string} name
   */
  function onSelectChange(name, option) {
    let value = '';
    const handlers = {
      assignee: setAssigneeOption,
      customer: setCustomerOption,
    };
    handlers[name](option);
    if (option) {
      ({ value } = option);
    }
    setState({
      ...state,
      [name]: value,
    });
  }

  function onWarehouseChange(option) {
    const warehouses = option.map(function ({ value }) {
      return value;
    });
    setWarehouseOption(option);
    setState({
      ...state,
      warehouses,
    });
  }

  useEffect(() => {
    if (userId) {
      const assignee = assigneeOptions.find(function ({ value }) {
        return value === userId;
      });
      if (assignee) {
        onSelectChange('assignee', assignee);
      }
    }
  }, [users, userId]);

  useEffect(() => {
    if (customerId && customerOptions) {
      const customer = customerOptions?.find(function ({ value }) {
        return value === customerId;
      });
      if (customer) {
        onSelectChange('customer', customer);
      }
    }
  }, [customerId, customerOptions]);

  return (
    <FlexRow>
      <FlexCol flexWidth={3}>
        <StyledSubHeading>Item Type</StyledSubHeading>
        <FlexRow>
          <FlexCol flexWidth={12}>
            {errors.type ? (
              <StyledErrorLabel>{errors.type}</StyledErrorLabel>
            ) : null}
          </FlexCol>
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <CheckBox
              caption="Sale"
              inputId="sale-checkbox"
              isChecked={state.type === SALE}
              description={
                <StyledList>
                  <StyledListItem>
                    For items that you are selling.
                  </StyledListItem>
                  <StyledListItem>
                    Order will include total sale price.
                  </StyledListItem>
                </StyledList>
              }
              onChange={onOrderTypeCheckChange.bind(null, SALE)}
            />
          </FlexCol>
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <CheckBox
              caption="Lease"
              isChecked={isLease}
              inputId="lease-checkbox"
              onChange={onOrderTypeCheckChange.bind(null, LEASE)}
              description={
                <StyledList>
                  <StyledListItem>
                    For items you want to track their condition as they move
                    from storage to project site and back.
                  </StyledListItem>
                  <StyledListItem>
                    You can choose to Include or exclude lease price.
                  </StyledListItem>
                  <StyledListItem>A return date is required.</StyledListItem>
                </StyledList>
              }
            />
          </FlexCol>
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <StyledSubHeading>Status</StyledSubHeading>
            <StyledStatus>{capitalizeFirstLetter(statusName)}</StyledStatus>
          </FlexCol>
        </FlexRow>
      </FlexCol>
      <FlexCol flexWidth={9}>
        <FlexRow>
          {isLease ? (
            <FlexCol flexWidth={4}>
              <ToggleSwitch
                isRequired
                checked={includeLeasePrice}
                caption="Include Lease Price?"
                onChange={onIncludeLeasePriceChange}
              />
            </FlexCol>
          ) : null}
          <FlexCol flexWidth={4}>
            <TextInput
              isRequired
              name="name"
              inputId="name"
              inputType="text"
              marginBottom={5}
              value={state.name}
              caption="Order Name"
              disabled={isReadOnly}
              onChange={onInputChange}
              errorMessage={errors.name}
              placeholder="Enter order name"
            />
          </FlexCol>
          <FlexCol flexWidth={4}>
            <SelectInput
              isRequired
              name="customer"
              marginBottom={5}
              isLoading={false}
              isClearable={true}
              isSearchable={true}
              disabled={isReadOnly}
              value={customerOption}
              caption="Customer Name"
              ariaLabelledBy="customer"
              options={customerOptions}
              placeholder="Select customer"
              errorMessage={errors.customer}
              onChange={onSelectChange.bind(null, 'customer')}
            />
          </FlexCol>
          {!isLease ? warehouseInput : null}
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={4}>
            <SelectInput
              name="assignee"
              marginBottom={5}
              isLoading={false}
              isClearable={true}
              isSearchable={true}
              caption="Assigned To"
              disabled={isReadOnly}
              value={assigneeOption}
              ariaLabelledBy="assignee"
              options={assigneeOptions}
              placeholder="Select user"
              errorMessage={errors.assignee}
              onChange={onSelectChange.bind(null, 'assignee')}
            />
          </FlexCol>
          <FlexCol flexWidth={4}>
            <DatePicker
              marginBottom={5}
              name="deliveryDate"
              disabled={isReadOnly}
              inputId="deliveryDate"
              caption="Delivery Date"
              selected={state.deliveryDate}
              isRequired={requireDeliveryDate}
              placeholder="Select delivery date"
              errorMessage={errors.deliveryDate}
              onChange={onDateChange.bind(null, 'deliveryDate')}
              description="Select date items should be delivered"
            />
          </FlexCol>
          <FlexCol flexWidth={4}>
            {state.type === LEASE ? (
              <DatePicker
                isRequired
                name="returnDate"
                inputId="returnDate"
                disabled={isReadOnly}
                caption="Return Date"
                selected={state.returnDate}
                placeholder="Select return date"
                errorMessage={errors.returnDate}
                onChange={onDateChange.bind(null, 'returnDate')}
                description="Select date items should be returned"
              />
            ) : null}
          </FlexCol>
        </FlexRow>
        <FlexRow>
          {isLease ? (
            <>
              {warehouseInput}
              <FlexCol flexWidth={4}></FlexCol>
              <FlexCol flexWidth={4}></FlexCol>
            </>
          ) : null}
        </FlexRow>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <TextArea
              height={50}
              name="terms"
              inputId="terms"
              inputType="text"
              marginBottom={5}
              value={state.terms}
              disabled={isReadOnly}
              onChange={onInputChange}
              errorMessage={errors.terms}
              caption="Terms & Conditions"
              placeholder="Enter terms & conditions"
            />
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </FlexRow>
  );
}

export default General;
