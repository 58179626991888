import { endPoints } from 'api/';
import { getUpload } from 'actions/item/';
import { UPLOAD_ITEM } from 'actions/types';

/**
 * @description Uploads an excel items spreadsheet.
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @returns Uploaded items list
 */
function uploadItem(payload, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPLOAD_ITEM,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const item = await apiClient(endPoints.UPLOAD_ITEM, payload);
        dispatch(getUpload({ companyId }));
        return item;
      },
    };

    dispatch(action);
  };
}

export default uploadItem;
