import { endPoints } from 'api/';
import { DELETE_CUSTOMER, UPDATE_CUSTOMER } from 'actions/types';

/**
 * @description Updates customer object. This will also act as `delete` action by `disabling` status.
 * @param {string} customerId
 * @param {{}} payload
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns Customer object
 */
function update(customerId, payload, isDeleting = false) {
  const actionType = isDeleting ? DELETE_CUSTOMER : UPDATE_CUSTOMER;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const customer = await apiClient(
          endPoints.UPDATE_CUSTOMER,
          payload,
          customerId,
        );
        return customer;
      },
    };
    dispatch(action);
  };
}

export default update;
