import { func } from 'prop-types';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { history } from 'store/store';
import { getTags } from 'actions/tag/';
import { getUsers } from 'actions/users/';
import menuList from 'constants/menuList';
import { primary } from 'constants/color';
import {
  StyledMenuNav,
  ProgressBarWrapper,
} from 'containers/MenuBar/MenuBar.styled';
import { showModal } from 'actions/modals';
import { showNotify } from 'actions/notify';
import { setActiveTopMenu } from 'actions/menus';
import { getCustomers } from 'actions/customer/';
import { getCategories } from 'actions/category/';
import { getConditions } from 'actions/condition/';
import { getWarehouses } from 'actions/warehouses/';
import { ACCOUNT_SETUP_PATH } from 'constants/general';
import { getNotifications } from 'actions/notification/';
import MenuBarItem from 'components/MenuBar/MenuBarItem';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { ReactComponent as MenuBarsIcon } from 'icons/bars.svg';

/**
 * @param {{
 *  onSideNavToggle : Function
 *  }} param
 */
function MenuBar({ onSideNavToggle }) {
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const user = useSelector(({ user }) => user);
  const { pathname: pathName } = routerLocation;
  const inventoryButtons = menuList[0].topMenuButtons;
  const [isOnBoarding, setIsOnBoarding] = useState(false);
  const activeTopMenu =
    useSelector(({ activeTopMenu }) => activeTopMenu) || inventoryButtons;
  const { caption, progress } = useSelector(({ onboarding }) => onboarding);
  const activeMenu = useSelector(({ activeMenu }) => activeMenu) || menuList[0]; // assumes 'dashboard' as default
  const { topMenuButtons } = activeMenu;

  useEffect(() => {
    const setupRoute = pathName === ACCOUNT_SETUP_PATH;
    setIsOnBoarding(setupRoute);
  }, [pathName]);

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;
      dispatch(getTags(companyId));
      dispatch(getUsers({ companyId }));
      dispatch(getConditions(companyId));
      dispatch(getCategories(companyId));
      dispatch(getCustomers({ companyId }));
      dispatch(getWarehouses({ companyId }));
      dispatch(getNotifications({ companyId }));
    }
  }, [dispatch, user]);

  /**
   * @param {{
   *  modal : string
   *  }} menu
   */
  function onMenuClick(menu) {
    dispatch(setActiveTopMenu(menu));
    const { modal, redirect } = menu;
    if (modal) {
      dispatch(showModal(modal));
    }
    if (redirect) {
      history.push(redirect);
    }
  }

  function onToggleSideNav() {
    if (isOnBoarding) {
      const message = 'Side nav toggle is disabled while onboarding';
      dispatch(showNotify(message));
    } else {
      onSideNavToggle();
    }
  }

  if (isOnBoarding) {
    return (
      <StyledMenuNav>
        <MenuBarItem
          caption={caption}
          onClick={onToggleSideNav}
          menuIcon={<MenuBarsIcon />}
        />
        <ProgressBarWrapper>
          <ProgressBar
            barHeight={10}
            barWidth={100}
            barColor={primary}
            progress={progress}
          />
        </ProgressBarWrapper>
      </StyledMenuNav>
    );
  }

  return (
    <>
      <StyledMenuNav>
        <MenuBarItem
          onClick={onToggleSideNav}
          menuIcon={<MenuBarsIcon />}
          caption={activeMenu.caption}
        />

        {topMenuButtons.map(
          /**
           * @param {{
           *  Icon : JSX,
           *  name : string,
           *  caption : string,
           *  }} menu
           */
          function (menu) {
            const { Icon, name, caption } = menu;
            return (
              <MenuBarItem
                key={name}
                caption={caption}
                menuIcon={<Icon />}
                onClick={onMenuClick.bind(null, menu)}
                isActive={activeTopMenu.name === name}
              />
            );
          },
        )}
      </StyledMenuNav>
    </>
  );
}

MenuBar.propTypes = {
  onSideNavToggle: func,
};

export default MenuBar;
