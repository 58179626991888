import {
  ASSIGN_ORDER,
  GET_ASSIGNED_ORDERS,
  UPDATE_ASSIGNED_ORDER,
} from 'actions/types';

let cacheList = [];
const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Stores orders assignment array details in redux state.
 * @param {[]} state
 * @param {{}} action
 * @returns {[]}
 */
function assigned(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${ASSIGN_ORDER}_SUCCESS`) {
    cacheList = [...cacheList, payload];
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${GET_ASSIGNED_ORDERS}_FAILURE`) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (type === `${GET_ASSIGNED_ORDERS}_SUCCESS`) {
    cacheList = payload;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${UPDATE_ASSIGNED_ORDER}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = cacheList.map(function (order) {
      if (order.id === id) {
        return {
          ...order,
          ...updatedValues,
        };
      }
      return order;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  return state;
}

export default assigned;
