import { endPoints } from 'api/';
import { GET_ITEM_UPLOAD } from 'actions/types';

/**
 * @description Get a list of all uploaded items
 * @param {{
 *  companyId : string,
 * }} params
 * @returns Array of uploaded item objects
 */
function getUpload({ companyId }) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const query = `?where={"company_id": "${companyId}"}`;
    const action = {
      type: GET_ITEM_UPLOAD,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const uploads = await apiClient(endPoints.GET_ITEM_UPLOAD, {}, query);
        return uploads;
      },
    };
    dispatch(action);
  };
}

export default getUpload;
