import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { CUSTOMER_MODAL } from 'constants/modal';
import { createCustomer, updateCustomer } from 'actions/customer/';
import CustomerForm from 'components/Customer/CustomerForm/CustomerForm';

function CustomerModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const isOpen = modal?.type === CUSTOMER_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const modalCaption = isEditing ? 'Update Customer' : 'Create A Customer';
  const { initialValue } = modal;

  function onModalClose() {
    dispatch(clearModal(CUSTOMER_MODAL));
  }

  function onSubmit(payload) {
    const { company_id: companyId } = user;
    if (isEditing) {
      const { id: customerId } = initialValue || {};
      payload.append('id', customerId);
      dispatch(updateCustomer(customerId, payload, false));
    } else {
      payload.append('company_id', companyId);
      dispatch(createCustomer(payload));
    }
  }

  return (
    <Modal
      modalTop={10}
      modalLeft={10}
      isOpen={isOpen}
      modalWidth={80}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <CustomerForm
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default CustomerModal;
