import { endPoints } from 'api/';
import { GET_STOCK_MOVE } from 'actions/types';

/**
 * @description Get a list of all item movements in the company's warehouse.
 * @param {{
 *   lowerDate : string
 *   upperDate : string
 *   warehouseId : string
 *   inventoryId : string
 * }} param
 */
function getStockMove({ warehouseId, inventoryId, lowerDate, upperDate }) {
  /**
   *  @param {Function} dispatch
   */
  return function (dispatch) {
    const dateFilter =
      lowerDate && upperDate
        ? `,"created_at": "btwn::${lowerDate},${upperDate}"`
        : '';
    const itemFilter = inventoryId ? `,"inventory_id": "${inventoryId}"` : '';
    const query = `?where={"warehouse_id": "${warehouseId}"${itemFilter}${dateFilter}}&isGraph=true`;

    const action = {
      type: GET_STOCK_MOVE,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const items = await apiClient(endPoints.GET_STOCK_MOVE, {}, query);

        return items;
      },
    };

    dispatch(action);
  };
}

export default getStockMove;
