import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_CONDITION } from 'actions/types';

/**
 * @description Get a list of all conditions.
 * @param {string} companyId Current user's company id.
 * @returns Array of condition objects
 */
function query(companyId) {
  const query = {
    where: {
      status: active,
      company_id: companyId,
    },
  };
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_CONDITION,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const condition = await apiClient(endPoints.GET_CONDITION, {}, query);
        return condition;
      },
    };
    dispatch(action);
  };
}

export default query;
