import { endPoints } from 'api/';
import { GET_ITEMS } from 'actions/types';
import { disabled } from 'constants/status';
import { ARCHIVED } from 'constants/inventory';

/**
 * @description Get a list of all items
 * @param {{
 *  companyId : string,
 *  lowerDate : string,
 *  upperDate : string,
 *  unArchived : boolean,
 * }} params
 * @returns Array of item objects
 */
function query({ companyId, lowerDate, upperDate, unArchived = true }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const unArchivedFilter = unArchived
    ? `, "status": "notin::${ARCHIVED},${disabled}"`
    : '';
  /**
   * @param {Function} dispatch
   * @param {Function} getState
   */
  return function (dispatch, getState) {
    const {
      warehouses: { data: warehouses },
    } = getState();
    /**
     * Guard to ensure we're not iterating over an empty array.
     * Which will pipe error to apiClient making HTTP request.
     */
    if (warehouses?.length > 0) {
      const warehouseIds = [...warehouses].map(function ({ id }) {
        return id;
      });
      const query = `?where={"company_id": "${companyId}", "warehouse_id": "in::${warehouseIds.toString()}", "quantity": "gt::0"${dateFilter}${unArchivedFilter}}`;
      const action = {
        type: GET_ITEMS,
        /**
         * @param {Function} apiClient
         */
        request: async function (apiClient) {
          const items = await apiClient(endPoints.GET_ITEMS, {}, query);
          return items;
        },
      };
      dispatch(action);
    }
  };
}

export default query;
