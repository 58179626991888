import { array, func, object } from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';

import {
  TextInput,
  CreaTable,
  PhotoInput,
  SelectInput,
} from 'components/Inputs/';
import countries from 'constants/country';
import currencyList from 'constants/currencies';
import unitsList from 'constants/quantityUnits';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { StyledWrapper } from 'components/Company/Form/Form.styled';
import { ReactComponent as EditIcon } from 'icons/pencil_circle.svg';
import { capitalizeFirstLetter, parseJSON, validateEmail } from 'utils/';

/**
 * @param {{
 *  currencies : []
 *  initialValue : {}
 *  onSubmit : Function
 *  onModalClose : Function
 * }} param
 */
function Form({
  onSubmit,
  initialValue,
  onModalClose,
  currencies = currencyList,
}) {
  const {
    photo,
    name = '',
    country = '',
    id: companyId,
    contact_details: contactDetails,
    other_details: otherDetails = null,
  } = initialValue || {};
  const contactsObj = parseJSON(contactDetails);
  const countryOptions = useMemo(() => {
    return countries.map(function ({ name, code }) {
      return {
        value: code,
        label: name,
      };
    });
  });
  const { email = '', phone = '', address } = contactsObj || {};
  const {
    tax_number: taxNumber = '',
    vat_amount: vatAmount = '',
    price_units: priceUnits = [],
    quantity_units: quantityUnits = [],
  } = otherDetails || {};
  const [currency] = priceUnits;
  const currentCountry = useMemo(() => {
    const option = countries.find(function ({ code }) {
      return code === country;
    });
    if (option) {
      return {
        value: option.code,
        label: option.name,
      };
    }
  }, [country]);
  const currentCurrency = currencies.find(function ({ value }) {
    return value === currency;
  });
  const currentQuantityUnits = quantityUnits?.map(function (unit) {
    return {
      label: unit,
      value: unit,
    };
  });
  const [state, setState] = useState({
    country,
    currency,
    taxNumber,
    vatAmount,
    quantityUnits,
    name: unescape(name),
    email: unescape(email),
    phone: unescape(phone),
    address: unescape(address),
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    vatAmount: '',
    quantityUnits: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(photo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(currentCountry);
  const [selectedUnits, setSelectedUnits] = useState(currentQuantityUnits);
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrency);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onSubmitCompany() {
    setIsSubmitting(true);
    const isFormValid = validateCompanyForm();
    if (isFormValid) {
      const formData = new FormData();
      const {
        name,
        phone,
        email,
        country,
        address,
        currency,
        taxNumber,
        vatAmount,
        quantityUnits,
      } = state;
      const contactDetails = {
        phone,
        email,
        address,
      };
      const otherDetailsObj = {
        ...otherDetails,
        tax_number: taxNumber,
        vat_amount: vatAmount,
        price_units: [currency],
        quantity_units: quantityUnits,
      };
      formData.append('name', name);
      formData.append('id', companyId);
      formData.append('country', country);
      if (companyLogo) {
        formData.append('photo', companyLogo);
      }
      formData.append('other_details', JSON.stringify(otherDetailsObj));
      formData.append('contact_details', JSON.stringify(contactDetails));
      onSubmit(formData, companyId);
    }
  }

  function validateCompanyForm() {
    let isFormValid = true;
    let errorObject = { ...errors };
    const optionalFields = ['taxNumber'];
    for (const [key, value] of Object.entries(state)) {
      if (!optionalFields.includes(key) && !value?.length) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setErrors(errorObject);
    return isFormValid;
  }

  /**
   * @param {{
   *  value: string
   * }} option
   */
  function onSelectChange(option, { name }) {
    let value = null;
    const handlers = {
      country: setSelectedCountry,
      currency: setSelectedCurrency,
      quantityUnits: setSelectedUnits,
    };
    handlers[name](option);
    if (name === 'quantityUnits') {
      value = option?.map(function ({ value }) {
        return value;
      });
    } else {
      value = option?.value;
    }
    setState({
      ...state,
      [name]: value,
    });
  }

  function onEditToggle() {
    setIsEditing(!isEditing);
  }

  useEffect(() => {
    if (isSubmitting) {
      validateCompanyForm();
    }
  }, [state, isSubmitting]);

  return (
    <StyledWrapper>
      <FlexRow marginBottom={20}>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                inputType="text"
                value={state.name}
                disabled={!isEditing}
                caption="Company Name"
                onChange={onInputChange}
                placeholder="Enter name"
                errorMessage={errors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="email"
                inputId="email"
                inputType="text"
                value={state.email}
                disabled={!isEditing}
                caption="Company Email"
                onChange={onInputChange}
                placeholder="Enter email"
                errorMessage={errors.email}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                inputType="text"
                value={state.phone}
                disabled={!isEditing}
                caption="Company Phone"
                onChange={onInputChange}
                errorMessage={errors.phone}
                placeholder="e.g +254712345678"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="address"
                inputType="text"
                inputId="address"
                disabled={!isEditing}
                value={state.address}
                onChange={onInputChange}
                caption="Company Address"
                errorMessage={errors.address}
                placeholder="e.g 123, XYZ Street, City, Country"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="country"
                isLoading={false}
                caption="Country"
                isClearable={true}
                isSearchable={true}
                disabled={!isEditing}
                value={selectedCountry}
                options={countryOptions}
                ariaLabelledBy="country"
                onChange={onSelectChange}
                placeholder="Select country"
                errorMessage={errors.country}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                errorMessage=""
                inputType="text"
                name="taxNumber"
                inputId="taxNumber"
                disabled={!isEditing}
                value={state.taxNumber}
                onChange={onInputChange}
                placeholder="Enter tax number"
                caption="Company Tax Number (optional)"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                caption="VAT %"
                name="vatAmount"
                inputType="number"
                inputId="vatAmount"
                disabled={!isEditing}
                placeholder="Enter VAT"
                value={state.vatAmount}
                onChange={onInputChange}
                errorMessage={errors.vatAmount}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="currency"
                isLoading={false}
                caption="Currency"
                isClearable={true}
                isSearchable={true}
                options={currencies}
                disabled={!isEditing}
                value={selectedCurrency}
                ariaLabelledBy="currency"
                onChange={onSelectChange}
                placeholder="Select Currency"
                errorMessage={errors.currency}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <CreaTable
                isRequired
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                options={unitsList}
                name="quantityUnits"
                disabled={!isEditing}
                value={selectedUnits}
                caption="Quantity Units"
                onChange={onSelectChange}
                ariaLabelledBy="quantityUnits"
                errorMessage={errors.quantityUnits}
                noOptionsMessage="Enter Quantity Units"
                placeholder="Select preexisting or enter unit e.g cartons or bales"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="companyLogo"
                caption="Company Logo"
                imageUri={companyLogo}
                dataTestId="companyLogo"
                onChange={setCompanyLogo}
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Edit"
          icon={<EditIcon />}
          onClick={onEditToggle}
          className="edit-button"
        />
        <RoundButton
          caption="Save"
          icon={<ArrowIcon />}
          className="save-button"
          onClick={onSubmitCompany}
        />
        {onModalClose ? <CancelButton onClick={onModalClose} /> : null}
      </FlexRow>
    </StyledWrapper>
  );
}

Form.defaultProps = {
  initialValue: {},
};

Form.propTypes = {
  onSubmit: func,
  currencies: array,
  onModalClose: func,
  initialValue: object,
};

export default Form;
