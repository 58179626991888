import { endPoints } from 'api/';
import { ASSIGN_ORDER } from 'actions/types';

/**
 * @description Assigns order to a user.
 * @param {{}} payload
 * @returns Assigned oder object.
 */
function create(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: ASSIGN_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const assigned = await apiClient(endPoints.ASSIGN_ORDER, payload);
        return assigned;
      },
    };
    dispatch(action);
  };
}

export default create;
