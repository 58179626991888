import styled from 'styled-components';

import { white, black } from 'constants/color';

export const StyledGeneralWrapper = styled.div`
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: ${white};
`;

export const StyledButtonWrapper = styled.div`
  width: 20%;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledButtonRow = styled.div`
  padding: 10px;
  margin-top: -10px;
  margin-right: 10px;
  background-color: ${white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  button:not(:first-of-type) {
    margin-left: 20px;
  }
  /* Mobile Devices */
  @media (max-width: 480px) {
    flex-direction: column;
    button {
      margin-top: 10px;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .dropdown-button {
      margin-left: 5px !important;
    }
  }
`;

export const StyledOrderNumber = styled.label`
  display: flex;
  color: ${black};
  align-items: center;
  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-left: 10px;
  }
`;

export const StyledStatusWrapper = styled.div`
  width: 20%;
  margin: 0 10px;
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 96%;
  }
`;
