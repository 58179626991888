import { endPoints } from 'api/';
import { CREATE_CONDITION } from 'actions/types';

/**
 * @description Creates condition object.
 * @param {{}} payload
 * @returns Condition object
 */
function create(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_CONDITION,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const condition = await apiClient(endPoints.CREATE_CONDITION, payload);
        return condition;
      },
    };
    dispatch(action);
  };
}

export default create;
