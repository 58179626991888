import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { permissionMock } from 'fixture/';
import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { PEOPLE_MODAL } from 'constants/modal';
import { createPeople, updatePeople } from 'actions/people/';
import PeopleForm from 'components/People/PeopleForm/PeopleForm';

function PeopleModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const isOpen = modal?.type === PEOPLE_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const modalCaption = isEditing ? 'Update People' : 'Create People';
  const { initialValue } = modal;

  function onModalClose() {
    dispatch(clearModal(PEOPLE_MODAL));
  }

  /**
   * @param {FormData} payload
   */
  function onSubmit(payload) {
    const { company_id: companyId } = user;
    const { id: peopleId } = initialValue || {};
    payload.append('company_id', companyId);
    if (isEditing) {
      payload.append('id', peopleId);
      dispatch(updatePeople(peopleId, payload, false));
    } else {
      dispatch(createPeople(payload, companyId));
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} caption={modalCaption}>
      <PeopleForm
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
        permissions={permissionMock}
      />
    </Modal>
  );
}

export default PeopleModal;
