import { endPoints } from 'api/';
import { ASSIGNED } from 'constants/inventory';
import { assignOrder, updateAssign } from 'actions/order/';
import { DELETE_ORDER, UPDATE_ORDER } from 'actions/types';

/**
 * @description Updates order object. This will also act as `delete` action by `disabling` status.
 * @param {string} orderId
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns Order object
 */
function update(orderId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_ORDER : UPDATE_ORDER;

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const {
          notes,
          userId,
          returnDate,
          deliveryDate,
          assignmentId,
          skipAssignment,
          ...rest
        } = payload;
        let props = {};
        const data = { ...rest, notes };
        if (returnDate) {
          props = { return_date: returnDate };
        }
        if (deliveryDate) {
          props = { ...props, delivery_date: deliveryDate };
        }
        const order = await apiClient(endPoints.UPDATE_ORDER, data, orderId);
        if (order.status === 200) {
          const {
            data: { response },
          } = order;
          if (!skipAssignment) {
            const data = {
              notes,
              ...props,
              user_id: userId,
              id: assignmentId,
            };
            if (assignmentId) {
              dispatch(updateAssign(assignmentId, data));
            } else {
              const data = {
                notes,
                ...props,
                user_id: userId,
                status: ASSIGNED,
                order_id: response.id,
                company_id: companyId,
                date_assigned: new Date().getTime(),
              };
              dispatch(assignOrder(data));
            }
          }
        }
        return order;
      },
    };
    dispatch(action);
  };
}

export default update;
