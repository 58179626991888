import { endPoints } from 'api/';
import { CREATE_TAG } from 'actions/types';

/**
 * @description Creates tag object.
 * @param {{}} payload
 * @returns Tag object
 */
function create(payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_TAG,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const tag = await apiClient(endPoints.CREATE_TAG, payload);
        return tag;
      },
    };
    dispatch(action);
  };
}

export default create;
