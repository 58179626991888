import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/Tag/';
import { active } from 'constants/status';
import Modal from 'containers/Modal/Modal';
import { TAG_MODAL } from 'constants/modal';
import { clearModal } from 'actions/modals/';
import { createTag, updateTag } from 'actions/tag/';

function TagModal() {
  const dispatch = useDispatch();
  const modalCaption = 'Manage Tags';
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: tags, isLoading } = useSelector(({ tag }) => tag);
  const isOpen = modal?.type === TAG_MODAL;
  const type = modal?.initialValue;

  const filteredTags = useMemo(() => {
    return tags.filter(function (tag) {
      return tag?.status === active && tag?.tagtype === type;
    });
  }, [type, tags]);

  function onModalClose() {
    dispatch(clearModal(TAG_MODAL));
  }

  /**
   * @param {{}} tagObj
   * @param {boolean} isEditing
   * @param {boolean} isDeleting
   */
  function onSubmit(tagObj, isEditing, isDeleting) {
    const { company_id: companyId } = user;
    const { id: tagId } = tagObj;
    if (isEditing) {
      const payload = {
        ...tagObj,
        company_id: companyId,
      };
      dispatch(updateTag(tagId, payload, isDeleting));
    } else {
      const payload = {
        ...tagObj,
        company_id: companyId,
      };
      dispatch(createTag(payload, companyId));
    }
  }
  return (
    <Modal
      modalTop={5}
      modalLeft={30}
      modalWidth={40}
      isOpen={isOpen}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Tag
        type={type}
        onSubmit={onSubmit}
        tags={filteredTags}
        isLoading={isLoading}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default TagModal;
