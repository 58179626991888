import {
  GET_PEOPLES,
  CREATE_PEOPLE,
  UPDATE_PEOPLE,
  DELETE_PEOPLE,
} from 'actions/types';
import { capitalizeFirstLetter } from 'utils/';

let cacheList = [];
const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Stores peoples array details in redux state.
 * @param {[]} state
 * @param {{}} action
 * @returns {[]}
 */
function people(state = initialState, action) {
  const { type, payload } = action;
  if (type === `${GET_PEOPLES}_FAILURE`) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (type === `${GET_PEOPLES}_SUCCESS`) {
    const peoples = [...payload].map(function (people) {
      return {
        ...people,
        name: `${people.first_name} ${people.last_name}`,
        permission: capitalizeFirstLetter(people.usertype),
      };
    });
    cacheList = peoples;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${CREATE_PEOPLE}_SUCCESS`) {
    const people = {
      ...payload,
      name: `${payload.first_name} ${payload.last_name}`,
      permission: capitalizeFirstLetter(payload.usertype),
    };
    cacheList = [...cacheList, people];
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${UPDATE_PEOPLE}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = cacheList.map(function (people) {
      if (people.id === id) {
        return {
          ...people,
          ...updatedValues,
          permission: capitalizeFirstLetter(updatedValues.usertype),
          name: `${updatedValues.first_name} ${updatedValues.last_name}`,
        };
      }
      return people;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  if (type === `${DELETE_PEOPLE}_SUCCESS`) {
    const { id } = payload;
    const data = cacheList.filter(function (people) {
      return people.id !== id;
    });
    cacheList = data;
    return {
      data: cacheList,
      isLoading: false,
    };
  }
  return state;
}

export default people;
