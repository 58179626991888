import { endPoints } from 'api/';
import { UPDATE_ASSIGNED_ORDER } from 'actions/types';

/**
 * @description Updates assigned order object.
 * @param {string} assignId
 * @param {{}} payload
 * @returns Assigned order object
 */
function update(assignId, payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_ASSIGNED_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const assigned = await apiClient(
          endPoints.UPDATE_ASSIGNED_ORDER,
          payload,
          assignId,
        );
        return assigned;
      },
    };
    dispatch(action);
  };
}

export default update;
