export const ASC = 'ASC';
export const DESC = 'DESC';
export const INVALID_URL = [
  '',
  'image',
  'url',
  'null',
  null,
  undefined,
  'undefined',
];
export const REORDER_LEVEL = 5;
export const ONBOARDING_STEPS = 8;
export const LOGIN_PATH = '/login';
export const EXPIRY_DAYS_NOTIFY = 5;
export const LOCK_SCREEN_PATH = '/lock';
export const MOBILE_VIEW_BREAKPOINT = 768;
export const DASHBOARD_PATH = '/dashboard';
export const INVENTORY_PATH = '/inventory';
export const USER_LOCAL_STORAGE = 'wezzaUser';
export const SUPPORT_PATH = '/general/support';
export const NOTIFICATION_PATH = '/notification';
export const ITEM_PATH = `${INVENTORY_PATH}/item`;
export const ITEM_EDIT_PATH = `${ITEM_PATH}/:id`;
export const ACCOUNT_SETUP_PATH = '/account/setup';
export const ORDER_DATE_FILTER = 'order_date_filter';
export const PASSWORD_RESET_PATH = '/password-reset';
export const ACCOUNT_SIGNUP_PATH = '/account/signup';
export const SIDE_NAV_LOCAL_STORAGE = 'wezzaSideNav';
export const TOKEN_COOKIE_NAME = 'wezza_access_token';
export const IMPORT_PATH = `${INVENTORY_PATH}/import`;
export const CONGRATULATIONS_PATH = '/congratulations';
export const SIDE_NAV_ACTIVE_MENU = 'wezzaSideNavActive';
export const NEW_ORDER_PATH = `${INVENTORY_PATH}/new-order`;
export const APP_SUPPORT_LINK = 'https://henga.co/support/';
export const ORDER_PATH = `${INVENTORY_PATH}/order-dashboard`;
export const EDIT_ORDER_PATH = `${INVENTORY_PATH}/manage-order/:id`;
export const RETURN_ORDER_PATH = `${INVENTORY_PATH}/return-order/:id`;
